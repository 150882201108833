export default {
  data: function data() {
    return {
      windowSize: {
        x: 0,
        y: 0
      },
      header: 64,
      contentPadding: 24,
      tableHeader: 0,
      tableFooter: 59,
      footer: 48,
      extra: 0
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.windowSize.y - this.header - this.contentPadding - this.tableHeader - this.tableFooter - this.footer - this.extra;
    }
  },
  methods: {
    onResize: function onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight
      };
    }
  }
};